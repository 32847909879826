/* PROGRESS BAR
http://tutorialzine.com/2013/10/buttons-built-in-progress-meters/
*/

/* The colorful bar that grows depending on the progress */

.progress-button .tz-bar {
    background-color: #000;
    height: 4px;
    bottom: 0;
    left: 0;
    width: 0;
    position: absolute;
    z-index: 1;

    border-radius: 0 0 2px 2px;

    -webkit-transition: width 0.5s, height 0.5s;
    -moz-transition: width 0.5s, height 0.5s;
    transition: width 0.5s, height 0.5s;
}

/* The bar can be either horizontal, or vertical */

.progress-button .tz-bar.background-horizontal {
    top: 55%;
    height: 4%;
    border-radius: 2px;
}

.progress-button .tz-bar.background-vertical {
    height: 0;
    top: 0;
    width: 100%;
    border-radius: 2px;
}

/* Phone-first approach */
@media only screen and (min-width: 480px) {
    .progress-button .tz-bar.background-horizontal {
        height: 2%;
    }
}
