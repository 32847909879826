textarea.form-control {
  min-height: 100px;
}

#adminTable {
  input {
    // Make width of search fields on lists automatic size
    width: 100%;
  }
  thead td {
    min-width: 50px;
    input {
      padding: 1px;
    }
  }
}
.adminActionIcons {
  // Make it easier to click on buttons in admin
  i {
    font-size: 18px;
    margin-right: 8px;
  }
  a:last-child {
    i {
      margin-right: 0px;
    }
  }
}

.dataTable {
  // More space between action items
  .adminActionIcons {
    a {
      margin-left: 5px;
    }
  }
}

// Overrides
.navbar-nav > .user-menu > .dropdown-menu > .user-footer {
  background-color: #222d32;
}

.bootstrap-dialog .modal-header.bootstrap-dialog-draggable {
  cursor: move;
}
.tinywhite {
  width: 1px;
  height: 1px;
  border: 0px;
}

// Get google autocomplete working in modals
.pac-container {
  z-index: 9999 !important;
}

.spincenter {
  display: inline-block;
  font-size: 40px;
  line-height: 100%;
  padding-top: 100px;
  width: 100%;
  height: 400px;
  text-align: center;
  vertical-align: bottom;
}

.fullwidth {
  width: 100% !important;
}

.selectize-input.focus,
.selectize-input {
  border-radius: 0px;
}
.selectize-input {
  vertical-align: top;
}
.selectize-dropdown,
.selectize-dropdown.form-control {
  border-radius: 0px;
  margin: 0;
  z-index: 10000;
}

.selectize-dropdown [data-selectable] {
  margin-left: 5px;
  padding: 5px;
}

.dashboardwidget {
  .view_label,
  .view_content {
    float: none;
    width: 100%;
  }
}

.slider.slider-horizontal {
  width: 100%;
}

.imgpreview {
  clear: both;
  div {
    border: 1px solid black;
    background-color: #bbb;
    display: inline-block;
  }
}

.alert a.btn {
  text-decoration: none;
}

[id^="frmb-"][id$="-form-wrap"] {
  .form-group {
    margin-left: 0;
  }
  .checkbox {
    float: left;
  }
}

.help-block {
  margin-top: 0px;
  margin-bottom: 0px;
}

// anchor colour on difficult to read backgrounds

.bg-blue a {
  color: #fff;
}

// responsive pull classes
@media (min-width: 768px) {
  .pull-left-sm {
    float: left;
  }
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 992px) {
  .pull-left-md {
    float: left;
  }
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 1200px) {
  .pull-left-lg {
    float: left;
  }
  .pull-right-lg {
    float: right;
  }
}

.datepicker-dropdown {
  z-index: 9999 !important;
}

.checkbox {
  input[type="radio"] {
    vertical-align: middle;
  }
}

.sidebar-menu > li > a > .zmdi {
  width: 20px;
}
.treeview-menu > li > a > .zmdi {
  width: 20px;
}
.pngbackground {
  background-image: url("data:image/gif;base64,R0lGODlhEAAQAKECAGVlZX9/f////////yH5BAEKAAIALAAAAAAQABAAAAIfhG+hq4jM3IFLJhoswNly/XkcBpIiVaInlLJr9FZWAQA7");
}

table.dataTable tbody tr.selected {
  background-color: #08c;
}
