/*
 * Skin: Yellow
 * ------------
 */
.skin-yellow-light .main-header .navbar {
  background-color: #f39c12;
}
.skin-yellow-light .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-yellow-light .main-header .navbar .nav > li > a:hover,
.skin-yellow-light .main-header .navbar .nav > li > a:active,
.skin-yellow-light .main-header .navbar .nav > li > a:focus,
.skin-yellow-light .main-header .navbar .nav .open > a,
.skin-yellow-light .main-header .navbar .nav .open > a:hover,
.skin-yellow-light .main-header .navbar .nav .open > a:focus,
.skin-yellow-light .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-yellow-light .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-yellow-light .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-yellow-light .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-yellow-light .main-header .navbar .sidebar-toggle:hover {
  background-color: #e08e0b;
}
@media (max-width: 767px) {
  .skin-yellow-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-yellow-light .main-header .navbar .dropdown-menu li a {
    color: #fff;
  }
  .skin-yellow-light .main-header .navbar .dropdown-menu li a:hover {
    background: #e08e0b;
  }
}
.skin-yellow-light .main-header .logo {
  background-color: #f39c12;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-yellow-light .main-header .logo:hover {
  background-color: #f39a0d;
}
.skin-yellow-light .main-header li.user-header {
  background-color: #f39c12;
}
.skin-yellow-light .content-header {
  background: transparent;
}
.skin-yellow-light .wrapper,
.skin-yellow-light .main-sidebar,
.skin-yellow-light .left-side {
  background-color: #f9fafc;
}
.skin-yellow-light .main-sidebar {
  border-right: 1px solid #d2d6de;
}
.skin-yellow-light .user-panel > .info,
.skin-yellow-light .user-panel > .info > a {
  color: #444444;
}
.skin-yellow-light .sidebar-menu > li {
  -webkit-transition: border-left-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease;
  transition: border-left-color 0.3s ease;
}
.skin-yellow-light .sidebar-menu > li.header {
  color: #848484;
  background: #f9fafc;
}
.skin-yellow-light .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  font-weight: 600;
}
.skin-yellow-light .sidebar-menu > li:hover > a,
.skin-yellow-light .sidebar-menu > li.active > a {
  color: #000000;
  background: #f4f4f5;
}
.skin-yellow-light .sidebar-menu > li.active {
  border-left-color: #f39c12;
}
.skin-yellow-light .sidebar-menu > li.active > a {
  font-weight: 600;
}
.skin-yellow-light .sidebar-menu > li > .treeview-menu {
  background: #f4f4f5;
}
.skin-yellow-light .sidebar a {
  color: #444444;
}
.skin-yellow-light .sidebar a:hover {
  text-decoration: none;
}
.skin-yellow-light .sidebar-menu .treeview-menu > li > a {
  color: #777777;
}
.skin-yellow-light .sidebar-menu .treeview-menu > li.active > a,
.skin-yellow-light .sidebar-menu .treeview-menu > li > a:hover {
  color: #000000;
}
.skin-yellow-light .sidebar-menu .treeview-menu > li.active > a {
  font-weight: 600;
}
.skin-yellow-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #d2d6de;
  margin: 10px 10px;
}
.skin-yellow-light .sidebar-form input[type="text"],
.skin-yellow-light .sidebar-form .btn {
  box-shadow: none;
  background-color: #fff;
  border: 1px solid transparent;
  height: 35px;
}
.skin-yellow-light .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-yellow-light .sidebar-form input[type="text"]:focus,
.skin-yellow-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-yellow-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-yellow-light .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
@media (min-width: 768px) {
  .skin-yellow-light.sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    border-left: 1px solid #d2d6de;
  }
}
